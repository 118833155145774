import { Box, Container, Hidden } from '@material-ui/core';
import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import styles from '../../pages/campaign/202501_lotterycampaign.module.scss';
import { Layout } from '../../components';
import JulyAppDownload from '../../components/Merchant/Campaign/2022AAA_202211/JulyAppDownload';
import SectionCampaignFooter from '../../components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignFooter';
import { SITE_METADATA } from '../../constants';
import PaidyLogoImg from '../../images/merchant/campaign/202304_6pay/paidy-logo.png';
import BannerImg from '../../images/campaign/202501_lotterycampaign/banner.png';
import BannerSpImg from '../../images/campaign/202501_lotterycampaign/banner-sp.png';

const SEOProps = {
  title: SITE_METADATA.campaign_202501_lottery.title,
  description: SITE_METADATA.campaign_202501_lottery.description,
};

const TERMS = [
  {
    title: 'キャンペーン期間',
    description: '2025年1月27日（月）0:00～2025年2月25日（火）23:59',
  },
  {
    title: 'キャンペーン概要・特典',
    description: (
      <>
        キャンペーン期間中に対象ショップの決済画面でペイディを選択し、1,000円以上お買い物すると、抽選で3人に1人キャッシュバックが当たります。
        <br />
        1等10,000円キャッシュバック、2等500円キャッシュバック、3等100円キャッシュバック。{' '}
        <br />
        <br />
        <small>
          キャンペーン終了後、2025年3月31日までに特典分がキャッシュバックされる予定です。
          <br />
          キャッシュバックはペイディアプリおよびMyPaidyに反映されます。決済画面には反映されません。
        </small>
      </>
    ),
  },
  {
    title: 'キャンペーン対象',
    description:
      '対象ショップの決済画面で「あと払い（ペイディ）」をご利用のお客様',
  },
  {
    title: 'ご注意事項',
    description: (
      <ul>
        <li>
          同期間に開催中の他のキャンペーンと併せて月間当選上限額は10万円になります。
        </li>
        <li>
          キャッシュバックは、決済画面でペイディを選択した場合のみ対象です。ペイディカードの利用は対象外となります。{' '}
        </li>
        <li>
          キャンペーン終了後2025年3月31日までに特典分がキャッシュバックされる予定です。また予定日より遅れることもありますので、予めご了承ください。{' '}
        </li>
        <li>
          キャッシュバック付与のタイミングで、ペイディの利用が無い場合、付与日以降でペイディをご利用いただいた際に、該当キャッシュバックが付与されます。あらかじめご了承ください。
        </li>
        <li>
          株式会社Paidyは本キャンペーンを、キャンペーン期間であっても予告なく変更または終了する場合がございます。
        </li>
        <li>
          不正と判断された場合やキャンセルがあった場合は、キャッシュバックが取り消される場合がございます。
        </li>
        <li>
          その他条件はペイディ
          <OutboundLink
            href="https://terms.paidy.com/cashback/"
            target="_blank"
            rel="noopener noreferrer"
          >
            キャッシュバック及びクーポン利用規約
          </OutboundLink>
          に準じます。
        </li>
        <li>
          ペイディに関するお問い合わせは
          <OutboundLink
            href="https://cs.paidy.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            ペイディヘルプページ
          </OutboundLink>
          へご連絡ください。
        </li>
      </ul>
    ),
  },
];

export default function Campaign202501Lottery() {
  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter>
      <Box className={styles.page}>
        <header className={styles.sectionHeader}>
          <img alt="paidy-logo" width={114} height={45} src={PaidyLogoImg} />
        </header>

        <section className={styles.sectionBanner}>
          <Hidden xsDown>
            <img alt="banner" height={522} width={1440} src={BannerImg} />
          </Hidden>
          <Hidden smUp>
            <img alt="banner-sp" width="100%" src={BannerSpImg} />
          </Hidden>
        </section>

        <section className={styles.sectionTerms}>
          <Container className={styles.container}>
            {TERMS.map((term) => (
              <Box key={term.title}>
                <p className={styles.title}>{term.title}</p>
                <p className={styles.description}>{term.description}</p>
                {term.notes && <p className={styles.notes}>{term.notes}</p>}
              </Box>
            ))}
          </Container>
        </section>
        <JulyAppDownload />

        <SectionCampaignFooter isThemeBlack />
      </Box>
    </Layout>
  );
}
