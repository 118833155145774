import React from 'react';
import { Box } from '@material-ui/core';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import BannerGeneralCashback from '../../images/daiseikaicampaign_202404/section/campaign/general_cashback_202501.png';
import BannerLottery20250122 from '../../images/daiseikaicampaign_202404/section/campaign/lottery_202501.png';
import BannerX from '../../images/daiseikaicampaign_202404/section/campaign/campaignX.png';
import Banner3Img from '../../images/daiseikaicampaign_202404/section/campaign/amazone_3.png';
import Banner10th from '../../images/daiseikaicampaign_202404/section/campaign/10th_anniversary_banner.png';
import TitleSection from './TitleSection';
import { PAIDY_HOST } from '../../constants';
import styles from './SectionCampaign.module.scss';

const CAMPAIGNS = [
  {
    href: `${PAIDY_HOST}/10th-anniversary/`,
    src: Banner10th,
    alt: '10th Anniversary',
  },
  {
    href: `${PAIDY_HOST}/campaign/general_cashback_202501/`,
    src: BannerGeneralCashback,
    alt: 'General Cashback',
  },
  {
    href: `${PAIDY_HOST}/campaign/202501_lotterycampaign/`,
    src: BannerLottery20250122,
    alt: 'Lottery Campaign',
  },
  {
    href: `https://x.com/PaidyJP/status/1883711529549430827/`,
    src: BannerX,
    alt: 'Campaign X',
  },
  {
    href: 'https://www.amazon.co.jp/b?node=8215625051',
    src: Banner3Img,
    alt: 'Amazon Cashback',
  },
];

export default function SectionCampaign({ id, number, text }) {
  return (
    <section className={styles.section} id={id}>
      <TitleSection number={number} text={text} />
      <p className={styles.text}>
        お得なキャンペーンを開催中。このチャンスに、
        <br />
        ぜひペイディでお買い物をお楽しみください。
      </p>
      <Box className={styles.container}>
        <div className={styles.campaignList}>
          {CAMPAIGNS.map((campaign) => (
            <OutboundLink
              href={campaign.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={campaign.src}
                alt={campaign.alt}
                width={311}
                height={336}
              />
            </OutboundLink>
          ))}
        </div>
      </Box>
    </section>
  );
}
