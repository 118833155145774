import React, { useState, useRef } from 'react';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Layout from '../../components/Layout/Layout';
import {
  SITE_METADATA,
  PAIDY_DOWNLOAD_HOST,
  SET_PASSKEY_URL,
} from '../../constants';
import styles from '../../styles/pages/passkeys.module.scss';
import PlayVideoBtnLgImg from '../../images/daiseikaicampaign_202404/play-btn-lg.svg';

const HeroSection = () => {
  return (
    <>
      <div className={cx(styles.heroContainer, styles.desktopHero)}>
        <div className={styles.heroContent}>
          <div className={styles.heroTitleBgCirle} />
          <img
            src={require('../../images/passkeys/hero/pattern.png')}
            alt="pattern"
            className={styles.heroImage1}
            height={60}
            width={60}
          />
          <img
            src={require('../../images/passkeys/hero/lock.png')}
            alt="password"
            className={styles.heroImage2}
            height={101}
            width={77}
          />
          <img
            src={require('../../images/passkeys/hero/faceid.png')}
            alt="faceid"
            className={styles.heroImage3}
            height={113}
            width={113}
          />
          <img
            src={require('../../images/passkeys/hero/pattern2.png')}
            alt="pattern"
            className={styles.heroImage4}
            height={55}
            width={55}
          />
          <h1 className={styles.heroTitle}>
            パスキーでログインをもっとかんたんで安全に
          </h1>
        </div>
      </div>
      <div className={cx(styles.heroContainer, styles.mobileHero)}>
        <div className={styles.heroContent}>
          <h1 className={styles.heroTitle}>
            <img
              src={require('../../images/passkeys/hero/pattern.png')}
              alt="pattern"
              className={styles.heroImage1Mobile}
              height={47}
              width={47}
            />
            <img
              src={require('../../images/passkeys/hero/lock.png')}
              alt="password"
              className={styles.heroImage2Mobile}
              height={65}
              width={50}
            />
            <img
              src={require('../../images/passkeys/hero/faceid.png')}
              alt="faceid"
              className={styles.heroImage3Mobile}
              height={63}
              width={63}
            />
            <img
              src={require('../../images/passkeys/hero/pattern2.png')}
              alt="pattern"
              className={styles.heroImage4Mobile}
              height={38}
              width={38}
            />
            <div className={styles.wrapTitle}>
              <p>
                <span className={styles.bkgTitle}>
                  <span className={styles.title}>
                    パスキーでログインを
                    <br />
                    もっとかんたんで安全に
                  </span>
                </span>
              </p>
            </div>
          </h1>
        </div>
        <svg>
          <defs>
            <filter id="gooey-filter">
              <feGaussianBlur
                in="SourceGraphic"
                result="blur"
                stdDeviation="2"
              />
              <feColorMatrix
                in="blur"
                result="colormatrix"
                type="matrix"
                values="1 0 0 0 0
                            0 1 0 0 0
                            0 0 1 0 0
                            0 0 0 19 -9"
              />
              <feBlend in="SourceGraphic" in2="colormatrix" />
            </filter>
          </defs>
        </svg>
      </div>
    </>
  );
};

const VideoSection = () => {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayVideo = () => {
    videoRef.current.play();
    setVideoPlaying(true);
  };

  return (
    <div className={cx(styles.section, styles.videoSection)}>
      <div className={cx(styles.sectionContent, styles.videoSectionContent)}>
        <div className={cx(styles.videoContent)}>
          <div
            className={styles.videoContainer}
            onClick={handlePlayVideo}
            role="button"
            tabIndex={0}
          >
            <video
              width={740}
              height={416}
              className={styles.video1}
              playsInline
              muted
              poster={require('../../images/passkeys/video_overlay.png')}
              ref={videoRef}
              controls={videoPlaying}
            >
              <source
                src={`${PAIDY_DOWNLOAD_HOST}/passkeys/video-passkeys.mp4`}
                type="video/mp4"
              />
              <track kind="captions" />
            </video>
            {!videoPlaying && (
              <div className={styles.overlay}>
                <div className={styles.shadow}>
                  <div className={styles.textWrapper}>
                    <p className={styles.text}>パスキーについて</p>
                    <span className={styles.time}>1:00</span>
                  </div>
                  <div
                    className={styles.playBtnWrapper}
                    onClick={handlePlayVideo}
                    role="button"
                    tabIndex={0}
                  >
                    <img
                      alt="play-video-btn"
                      src={PlayVideoBtnLgImg}
                      width={108}
                      height={108}
                      className={styles.btn}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.videoTextContent}>
          <p>
            新しいログイン方法「パスキー」を設定すると、生体認証やパスコード、パターンなどでかんたんにログインできます。メールアドレスと携帯電話番号の入力は不要。安全性も高く、安心してご利用いただけます。
          </p>
          <br />
          <p>
            私たちペイディは、使いやすさと、高い安全性を両立することが何よりも重要であると考え、日々アップデートを行っています。今後も、お客様が安心して利用できるサービスを目指して、改善を続けていきます。
          </p>
        </div>
        <div className={styles.videoCTA}>
          <OutboundLink href={SET_PASSKEY_URL} target="_blank">
            <button>パスキーを設定する</button>
          </OutboundLink>
          <p>パスキーの設定はペイディアプリから</p>
        </div>
      </div>
    </div>
  );
};

const BENEFIT_ITEMS = [
  {
    title: 'めんどうな入力不要',
    image: require('../../images/passkeys/benefit1.png'),
    subTitle: (
      <>
        メールアドレス・携帯電話番号の
        <br />
        入力が不要に。
      </>
    ),
    content: (
      <>
        <p>
          パスキーを設定すると生体認証やパスコード、パターンを利用してログインすることができます。
        </p>
        <br />
        <p>
          メールアドレスと携帯電話番号を入力する必要がなく、「登録したログイン情報を忘れてしまってログインできない」ということもありません。
        </p>
      </>
    ),
  },
  {
    title: '不正利用の防止にも効果的',
    image: require('../../images/passkeys/benefit2.png'),
    subTitle: <>安全性が高く、安心してご利用いただけます。</>,
    content: (
      <>
        <p>
          あらゆる文字列を試して不正にログインしたり、ひとつのパスキーを他のサービスで利用することができないので、なりすましやフィッシングなど、悪意のある第三者による不正利用の防止にも効果的です。
        </p>
        <br />
        <p>
          生成したパスキーは端末に保存され、認証情報がペイディに送信・保存されることはありません。
        </p>
      </>
    ),
  },
];

const BenefitsSection = () => {
  return (
    <div className={cx(styles.section, styles.benefitsSection)}>
      <div className={cx(styles.sectionContent, styles.benefitsSectionContent)}>
        {BENEFIT_ITEMS.map((item, index) => (
          <div className={styles.benefitItem} key={index}>
            <h2>{item.title}</h2>
            <img src={item.image} alt={item.title} width={184} height={184} />
            <h3>{item.subTitle}</h3>
            <div className={styles.benefitContent}>{item.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const HOW_TO_USE_ITEMS = [
  {
    image: require('../../images/passkeys/howto/howto1.png'),
    indexImg: require('../../images/passkeys/howto/1.svg'),
    imgWidth: 300,
    imgHeight: 218.62,
    text:
      'iPhoneまたはAndroid端末の設定から、生体認証やパスコード、パターンなどを有効にしてください。',
  },
  {
    image: require('../../images/passkeys/howto/howto2.png'),
    indexImg: require('../../images/passkeys/howto/2.svg'),
    imgWidth: 300,
    imgHeight: 158.62,
    text: 'ペイディアプリを開き、「アカウント」をタップしてください。',
  },
  {
    image: require('../../images/passkeys/howto/howto3.png'),
    indexImg: require('../../images/passkeys/howto/3.svg'),
    imgWidth: 300,
    imgHeight: 208.96,
    text: '「パスキー」をタップして、案内に従って設定を完了させてください。',
  },
];

const HowToUseSection = () => {
  return (
    <div className={cx(styles.section, styles.howToUseSection)}>
      <div className={cx(styles.sectionContent, styles.howToUseSectionContent)}>
        <h2>パスキーの設定方法</h2>
        <div className={styles.howToUseItems}>
          {HOW_TO_USE_ITEMS.map((item, index) => (
            <div className={styles.howToUseItem} key={index}>
              <img
                className={styles.howToUseItemImage}
                src={item.image}
                alt={item.text}
                width={item.imgWidth}
                height={item.imgHeight}
              />
              <div className={styles.howToUseItemTextWrapper}>
                <img
                  src={item.indexImg}
                  alt={index + 1}
                  width={24}
                  height={24}
                />
                <p className={styles.howToUseItemText}>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.howToUseCTA}>
          <OutboundLink href={SET_PASSKEY_URL} target="_blank">
            <button>パスキーを設定する</button>
          </OutboundLink>
          <p>パスキーの設定はペイディアプリから</p>
          <OutboundLink
            href="https://cs-support.paidy.com/support/solutions/articles/150000196076-%E3%83%91%E3%82%B9%E3%82%AD%E3%83%BC%E3%81%AE%E8%A8%AD%E5%AE%9A%E6%96%B9%E6%B3%95%E3%81%8C%E7%9F%A5%E3%82%8A%E3%81%9F%E3%81%84"
            target="_blank"
            className={styles.howToUseCTALink}
          >
            詳細な設定方法はこちら
          </OutboundLink>
        </div>
      </div>
    </div>
  );
};

const PasskeysPage = () => {
  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.passkeys.title,
        description: SITE_METADATA.passkeys.description,
      }}
    >
      <HeroSection />
      <VideoSection />
      <BenefitsSection />
      <HowToUseSection />
    </Layout>
  );
};

export default PasskeysPage;
