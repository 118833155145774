import React from 'react';
import { Container } from '@material-ui/core';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { PAIDY_HOST } from 'src/constants';
import ImgBanner1 from '../../../../images/campaign/general_cashback_202501/cashback-banner-1.png';
import ImgBanner2 from '../../../../images/campaign/general_cashback_202501/cashback-banner-2.png';
import ImgBanner3 from '../../../../images/campaign/general_cashback_202501/cashback-banner-3.png';
import ImgBanner4 from '../../../../images/campaign/general_cashback_202501/cashback-banner-4.png';
import ImgBanner5 from '../../../../images/campaign/general_cashback_202501/cashback-banner-5.png';
import styles from './CashbackBanner202501.module.scss';

const BANNERS = [
  {
    alt: 'Lottery Campaign',
    src: ImgBanner1,
    href: `${PAIDY_HOST}/campaign/202501_lotterycampaign`,
  },
  {
    alt: 'Amazon Cashback 3%',
    src: ImgBanner2,
    href: 'https://www.amazon.co.jp/b?node=8215625051',
  },
  {
    alt: 'Paidy 10th Anniversary',
    src: ImgBanner3,
    href: `${PAIDY_HOST}/10th-anniversary/`,
  },
  {
    alt: '4',
    src: ImgBanner4,
    href: 'https://x.com/PaidyJP/status/1883711529549430827/',
  },
  {
    alt: 'TVCM 2024 - Daiseikai',
    src: ImgBanner5,
    href: `${PAIDY_HOST}/daiseikaicampaign_202404/`,
  },
];

export default function CashbackBanner202501({ id = '' }) {
  return (
    <section className={styles.section} id={id}>
      <Container className={styles.container}>
        <h1 className={styles.title}>
          同時開催中のキャンペーンも
          <br />
          お見逃しなく！
        </h1>
        <div className={styles.banners}>
          {BANNERS.map((banner) => (
            <OutboundLink href={banner.href} target="_blank" key={banner.alt}>
              <img
                alt={banner.alt}
                src={banner.src}
                width={311}
                height={336}
                className={styles.banner}
              />
            </OutboundLink>
          ))}
        </div>
      </Container>
    </section>
  );
}
