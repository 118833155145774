import React from 'react';
import cx from 'classnames';
import { Link } from 'react-scroll';
import styles from './Shopping.module.scss';
import HorizontalScrollNavigation from '../HorizontalScrollNavigation';

const items = [
  {
    ImgComponent: () => (
      <img
        src={require('../../../images/guide-enjoy-shopping-1.svg')}
        alt={'買い物バッグのアイコン'}
      />
    ),
    figCaption: 'ショップのお支払い方法で「あと払い (ペイディ) 」を選択。',
  },
  {
    ImgComponent: () => (
      <img
        src={require('../../../images/guide-enjoy-shopping-2.svg')}
        alt={'スマートフォンのアイコン'}
      />
    ),
    figCaption: 'メアドと携帯番号を入力。',
  },
  {
    ImgComponent: () => (
      <img
        src={require('../../../images/guide-enjoy-shopping-3.svg')}
        alt={'認証コードを入力したスマートフォンの画像'}
      />
    ),
    figCaption: 'SMSで送信される4桁の認証コードを入力。',
  },
  {
    ImgComponent: () => (
      <img
        src={require('../../../images/guide-enjoy-shopping-4.svg')}
        alt={'買い物バッグのアイコン'}
      />
    ),
    figCaption: 'お買い物完了。',
  },
];

const Shopping = () => {
  return (
    <section id="shopping" className={styles.section}>
      <h2 className={styles.sectionTitle}>ペイディでお買い物を楽しもう</h2>
      <div className={styles.sectionTitleDescription}>
        <ul className={styles.customUl}>
          <li>
            <span>
              メールアドレスと携帯番号を入力するだけで、今すぐお買い物が楽しめます。
            </span>
          </li>
          <li>使えるお店は、Amazon、SHEIN、DMMなど、70万店舗以上。</li>
          <li>
            ペイディカードを発行すると、Visaマークのあるお店でクレジットカードとして利用できます。ネットショップで使えるバーチャルカードは最短5分で無料・即日発行。リアルカードを発行すると、街のお店でも利用可能に。
          </li>
          <li className={styles.noDecoration}>
            <Link
              to="paidy-card"
              smooth
              duration={500}
              className={cx(styles.link, 'ga_click_guide_to_details')}
            >
              ペイディカードの詳細はこちら
            </Link>
          </li>
          <li>利用通知メールが届くので、不正利用を防止できます。</li>
        </ul>
      </div>
      <HorizontalScrollNavigation items={items} />
    </section>
  );
};

export default Shopping;
