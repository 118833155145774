// @flow
import React from 'react';
import Layout from '../../components/Layout/Layout';
import { SITE_METADATA } from '../../constants';
import Hero from '../../components/Guide/Hero';
import Outline from '../../components/Guide/Outline';
import ShoppingSection from '../../components/Guide/Sections/Shopping';
import PaidyAppSection from '../../components/Guide/Sections/PaidyApp';
import PaymentSection from '../../components/Guide/Sections/Payment';
import EKYCSection from '../../components/Guide/Sections/EKYC';
import PaidyCard from '../../components/Guide/Sections/PaidyCard';
import ThreePay from '../../components/Guide/Sections/ThreePay';
import BudgetSection from '../../components/Guide/Sections/Budget';
import Passkey from '../../components/Guide/Sections/Passkey';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import styles from '../../styles/pages/guide.module.scss';

const GuidePage = ({ location }) => {
  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.guide.title,
        description: SITE_METADATA.guide.description,
      }}
    >
      <Hero />
      <Breadcrumb location={location} backgroundColor="#fafafa" />
      <Outline />
      <div className={styles.sectionDividerContainer}>
        <hr className={styles.sectionDivider} />
      </div>
      <ShoppingSection />
      <div className={styles.sectionDividerContainer}>
        <hr className={styles.sectionDivider} />
      </div>
      <PaidyAppSection />
      <div className={styles.sectionDividerContainer}>
        <hr className={styles.sectionDivider} />
      </div>
      <Passkey />
      <div className={styles.sectionDividerContainer}>
        <hr className={styles.sectionDivider} />
      </div>
      <PaymentSection />
      <div className={styles.sectionDividerContainer}>
        <hr className={styles.sectionDivider} />
      </div>
      <EKYCSection />
      <div className={styles.sectionDividerContainer}>
        <hr className={styles.sectionDivider} />
      </div>
      <PaidyCard />
      <div className={styles.sectionDividerContainer}>
        <hr className={styles.sectionDivider} />
      </div>
      <ThreePay />
      <div className={styles.sectionDividerContainer}>
        <hr className={styles.sectionDivider} />
      </div>
      <BudgetSection />
    </Layout>
  );
};

export default GuidePage;
