import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import styles from './Passkey.module.scss';
import HorizontalScrollNavigation from '../HorizontalScrollNavigation';
import { PAIDY_HOST } from '../../../constants';

const items = [
  {
    ImgComponent: () => (
      <img
        src={require('../../../images/guide-passkey-1.png')}
        alt={
          'iPhoneまたはAndroid端末の設定から、生体認証やパスコード、パターンなどを有効にしてください。'
        }
        width={183}
        height={183}
      />
    ),
    figCaption:
      'iPhoneまたはAndroid端末の設定から、生体認証やパスコード、パターンなどを有効にしてください。',
  },
  {
    ImgComponent: () => (
      <img
        src={require('../../../images/guide-passkey-2.png')}
        alt={'ペイディアプリを開き、「アカウント」をタップしてください'}
        width={183}
        height={183}
      />
    ),
    figCaption: 'ペイディアプリを開き、「アカウント」をタップしてください。',
  },
  {
    ImgComponent: () => (
      <img
        src={require('../../../images/guide-passkey-3.png')}
        alt={'「パスキー」をタップして、案内に従って設定を完了させてください。'}
        width={183}
        height={183}
      />
    ),
    figCaption: (
      <>
        「パスキー」をタップして、案内に従って設定を完了させてください。
        <br />
        <OutboundLink
          className={styles.link}
          href={`https://cs-support.paidy.com/support/solutions/articles/150000196076-%E3%83%91%E3%82%B9%E3%82%AD%E3%83%BC%E3%81%AE%E8%A8%AD%E5%AE%9A%E6%96%B9%E6%B3%95%E3%81%8C%E7%9F%A5%E3%82%8A%E3%81%9F%E3%81%84`}
          rel="noopener noreferrer"
          target="_blank"
        >
          詳細な設定方法はこちら
        </OutboundLink>
      </>
    ),
  },
];

const Passkey = () => {
  return (
    <section className={styles.section} id="passkey">
      <h2 className={styles.sectionTitle}>パスキーを設定しよう</h2>
      <div className={styles.sectionTitleDescription}>
        <ul className={styles.customUl}>
          <li>生体認証やパスコード、パターンなどでアプリにかんたんログイン</li>
          <li>メールアドレス・携帯電話番号の入力なし</li>
          <li>安全性が高く、不正利用の防止にも効果的</li>
          <li className={styles.noDecoration}>
            <OutboundLink
              href={`${PAIDY_HOST}/passkeys/?utm_source=web&utm_medium=web&utm_campaign=passkey&utm_content=web`}
              rel="noopener noreferrer"
              target="_blank"
              className={styles.link}
            >
              パスキーの詳細はこちら
            </OutboundLink>
          </li>
        </ul>
      </div>
      <HorizontalScrollNavigation items={items} />
    </section>
  );
};

export default Passkey;
