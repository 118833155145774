import React from 'react';
import { Box } from '@material-ui/core';
import { PAIDY_HOST, SITE_METADATA } from 'src/constants';

// Unchanged components
import { StickyNav } from 'src/components';
import Layout from 'src/components/Layout/Layout';
import LinearCTAButton from 'src/components/LinearCTAButton';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/GeneralCashback202402/SectionCampaignFooter';
import JulyPaymentSteps from 'src/components/Merchant/Campaign/GeneralCashback202407/JulyPaymentSteps';
import JulyAppDownload from 'src/components/Merchant/Campaign/GeneralCashback202407/JulyAppDownload';
import PaidyBenefits202501 from 'src/components/Campaign/general_cashback_202501/PaidyBenefits202501';

// Changed components
import SeptPayLater from 'src/components/Merchant/Campaign/GeneralCashback202501/SeptPayLater';
import Condition202501 from 'src/components/Merchant/Campaign/GeneralCashback202501/Condition202501';
import SectionNotes202501 from 'src/components/Merchant/Campaign/GeneralCashback202501/SectionNotes202501';
import CashbackBanner202501 from 'src/components/Merchant/Campaign/GeneralCashback202501/CashbackBanner202501';
import CashbackTopBanner202501 from 'src/components/Campaign/general_cashback_202501/CashbackTopBanner202501';

import styles from './tvcmlaunch_cashback_202404.module.scss';

const SEOProps = {
  ogImage: `${PAIDY_HOST}/ogp_10th_anniversary_v2.png`,
  title: SITE_METADATA.general_cashback_202501.title,
  description: SITE_METADATA.general_cashback_202501.description,
};
const NAVS = [
  'キャンペーン概要',
  'ペイディについて',
  'ペイディが選ばれる理由',
  'ご注意事項',
];

export default function GeneralCashback202501() {
  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter backgroundColor="white">
      <CashbackTopBanner202501 />
      <Box className={styles.ctaWrapper}>
        <LinearCTAButton text="使えるお店を探す" to={`${PAIDY_HOST}/shop/`} />
      </Box>
      <StickyNav navs={NAVS} />
      <Condition202501 id={NAVS[0]} />
      <SeptPayLater
        id={NAVS[1]}
        description="AmazonやSHEINのほか、Visaマークのあるお店ならどこでも、スマホだけで今すぐお買い物ができるあと払いサービスです。お支払いは翌月でOK。"
      />
      <JulyPaymentSteps />
      <CashbackBanner202501 />
      <PaidyBenefits202501 id={NAVS[2]} title="ペイディが選ばれる理由" />
      <SectionNotes202501 id={NAVS[3]} />
      <JulyAppDownload />
      <SectionCampaignFooter isThemeBlack />
    </Layout>
  );
}
