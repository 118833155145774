import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import ImgHowToUse1 from 'src/images/payments/how-to-use/how-to-use-1.svg';
import ImgHowToUse2 from 'src/images/payments/how-to-use/how-to-use-2.svg';
import ImgHowToUse3 from 'src/images/payments/how-to-use/how-to-use-3.svg';
import ImgHowToUse4 from 'src/images/payments/how-to-use/how-to-use-4.svg';
import LinearCTAButton from 'src/components/LinearCTAButton';
import styles from './PaymentHowToUse.module.scss';

const TITLE = 'ご利用方法';

const HOW_TO_USE = [
  {
    key: 'how-to-use-1',
    img: ImgHowToUse1,
    text: (
      <>
        1. ショップのお支払い方法で
        <br />
        「あと払い (ペイディ)」を選択。
      </>
    ),
  },
  {
    key: 'how-to-use-2',
    img: ImgHowToUse2,
    text: <>2. メアドと携帯番号を入力。</>,
  },
  {
    key: 'how-to-use-3',
    img: ImgHowToUse3,
    text: (
      <>
        3. SMSで送信される4桁の
        <br />
        認証コードを入力。
      </>
    ),
  },
  {
    key: 'how-to-use-4',
    img: ImgHowToUse4,
    text: <>4. お買い物完了。</>,
  },
];

export default function PaymentHowToUse({
  title = TITLE,
  data = HOW_TO_USE,
  textButton = 'ご利用方法の詳細を見る',
  customClassName = {},
  id = 'how-to-use',
  to = '/guide#shopping',
}) {
  // use useEffect hooks to be ignored by Gatsby during the build process,
  // this code should execute only in the browser environment
  const [classnames, setClassnames] = useState('ga_click_top_to_details ');

  useEffect(() => {
    if (window.location.pathname.indexOf('payments') > -1) {
      setClassnames('ga_click_payments_button ');
    }
  }, []);

  return (
    <section className={cx(styles.section, customClassName.section)} id={id}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.container}>
        {data.map((item, index) => (
          <div className={styles.item} key={item.key}>
            <img
              src={item.img}
              alt={`how-to-use-${index}`}
              className={cx(
                styles.img,
                item.smallText ? styles.imgHassmallText : ''
              )}
            />
            <div className={styles.textWrapper}>
              <p className={cx(customClassName.text, styles.text)}>
                {item.text}
              </p>
              {item.smallText && (
                <p className={styles.textSmall}>{item.smallText}</p>
              )}
            </div>
          </div>
        ))}
      </div>
      <LinearCTAButton text={textButton} to={to} className={classnames} />
    </section>
  );
}
