// @flow
import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { mainMenu } from './const';
import styles from './Header.module.scss';
import { trackLogin } from '../../utils';

const Header = ({
  siteTitle,
  hasNoHeaderNav,
  pathname,
  alwaysWhite,
}: {
  siteTitle: string,
  hasNoHeaderNav?: boolean,
  pathname: string,
  alwaysWhite?: boolean,
}) => {
  const [hasWhiteBg, setHasWhiteBg] = React.useState(alwaysWhite);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (!alwaysWhite) {
      const updateBgOnScroll = () => {
        setHasWhiteBg(window.scrollY > 0);
      };

      window.addEventListener('scroll', updateBgOnScroll);

      return () => window.removeEventListener('scroll', updateBgOnScroll);
    }
  }, []);

  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent
      ? { className: hasWhiteBg ? styles.activeWhiteBg : styles.activeLink }
      : {};
  };

  return (
    <header className={cx(styles.header, hasWhiteBg && styles.whiteBg)}>
      <div className={styles.headerInner}>
        <div className={styles.internals}>
          <h1 title={siteTitle} className={styles.logo}>
            <Link className="ga_click_paidy_logo" to="/">
              <img
                alt={siteTitle}
                src={require('../../images/logo-color-10th.svg')}
              />
            </Link>
          </h1>
          {!hasNoHeaderNav && (
            <nav className={styles.mainNav}>
              {mainMenu.map((menuItem) => (
                <div
                  className={cx(styles.linkBox, {
                    [styles.withDropdown]: !!menuItem.subMenu,
                    [styles.withActiveDropdown]:
                      !!menuItem.subMenu &&
                      menuItem.witActiveSubMenu &&
                      pathname.startsWith(menuItem.link),
                  })}
                  key={menuItem.title}
                >
                  {menuItem.isExternal ? (
                    <OutboundLink
                      href={menuItem.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {menuItem.title}
                    </OutboundLink>
                  ) : (
                    <Link
                      className="ga_click_global_navigation"
                      to={menuItem.link}
                      activeClassName={styles.active}
                      getProps={isPartiallyActive}
                    >
                      {menuItem.title}
                    </Link>
                  )}
                  {/* {menuItem.subMenu && (
                    <nav className={styles.subNav}>
                      {menuItem.subMenu.map((subMenuItem) => (
                        <Link
                          to={subMenuItem.link}
                          key={subMenuItem.title}
                          className={cx({
                            [styles.active]:
                              pathname.endsWith(subMenuItem.link) ||
                              (!subMenuItem.rootLink &&
                                pathname.includes(subMenuItem.link)),
                          })}
                        >
                          {subMenuItem.title}
                        </Link>
                      ))}
                    </nav>
                  )} */}
                </div>
              ))}
            </nav>
          )}
        </div>
        {!hasNoHeaderNav && (
          <nav className={styles.externals}>
            <OutboundLink
              className={styles.toMyPaidy}
              href="https://my.paidy.com/login/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                trackLogin();
              }}
            >
              MyPaidyログイン
            </OutboundLink>
          </nav>
        )}
      </div>
    </header>
  );
};

Header.defaultProps = {
  siteTitle: ``,
  isTransparent: false,
  hasNoHeaderNav: false,
  alwaysWhite: false,
};

export default Header;
