// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { Box, Container, Typography } from '@material-ui/core';
import { Layout } from '../../components';
import styles from '../../styles/pages/getting_started.module.scss';
import { getOgData } from '../../utils';
import Step from '../../components/GettingStarted/Step';

const steps = [
  {
    image: require('../../images/how_use_step1.svg'),
    title: 'Webお申し込み',
    content: (
      <>
        <Link to="/merchant/application/#documents">お申し込みフォーム</Link>
        より、事業内容や金融機関などの必要情報を入力してください。
      </>
    ),
  },
  {
    image: require('../../images/how_use_step2.svg'),
    title: '加盟店審査',
    content: '審査結果は10営業日以内（目安）にメールでご連絡いたします。',
  },
  {
    image: require('../../images/how_use_step3.svg'),
    title: '審査結果・条件通知書・アカウント情報をメールでご連絡',
    content: (
      <>
        <ul>
          <li>・ご利用条件は、メール添付の条件通知書に記載しております。</li>
          <li>
            ・ペイディ加盟店管理画面へのログイン情報（ID・パスワード）をファイル共有サービス「BOX」でご案内いたします。
          </li>
        </ul>
        <br />
        別途「noreply@box.com」よりご案内メールが届きますので、BOXアカウントの作成またはサインインをしていただき、ペイディ加盟店管理画面のログイン情報をご確認ください。
      </>
    ),
  },
  {
    image: require('../../images/how_use_step3a.svg'),
    title: 'ペイディ加盟店管理画面にログインしAPIキーを取得',
    content: (
      <>
        BOXより入手したIDとパスワードを用いて
        <a
          rel="noreferrer"
          target="_blank"
          href="https://merchant.paidy.com/login"
        >
          ペイディ加盟店管理画面
        </a>
        へログインし、ペイディのご利用に必要なAPIキーを取得してください。
      </>
    ),
  },
  {
    image: require('../../images/how_use_step3b.svg'),
    title: 'カートシステム設定の完了',
    content: (
      <>
        審査結果メールに記載する「
        <a
          rel="noreferrer"
          target="_blank"
          href="https://download.paidy.com/merchant/paidy_intro_guide.pdf"
        >
          Paidy導入ガイド
        </a>
        」内の「2.Paidyの設定・実装」に関するページをご参照の上、カートシステムの管理画面にて必要な設定を行ってください。
      </>
    ),
  },
  {
    image: require('../../images/how_use_step4.svg'),
    title: 'ご利用開始',
    content: (
      <>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://paidy.com/docs/jp/tokushoho.html"
        >
          特定商取引法に基づく表記
        </a>
        のページにペイディに関する記載が完了していることをご確認の上、ご利用の開始をお願いいたします。
      </>
    ),
  },
];

const GettingStartedPage = () => {
  return (
    <Layout hasNoHeader hasNoFooter>
      <Helmet
        meta={getOgData()}
        script={[
          {
            async: 'async',
            defer: 'defer',
            innerHTML: `
            (function(d){
              var l = d.createElement("link");
              var s = d.getElementsByTagName("script")[0];
              l.rel = "stylesheet";
              l.href = "https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&display=swap&subset=japanese";
              s.parentNode.insertBefore(l, s);
            })(document);
            `,
          },
        ]}
      />
      <Box className={styles.heroWrapper}>
        <img
          alt="Paidy logo"
          src={require('../../images/logo-color-10th.svg')}
          height="auto"
          loading="lazy"
          className={styles.heroImg}
        />
        <Typography variant="h1">ご利用開始までの流れ</Typography>
      </Box>
      <Box className={styles.steps}>
        <Container maxWidth="md" className={styles.stepsWrapper}>
          {steps.map((step, index) => (
            <Step
              key={`step-${index}`}
              index={index + 1}
              image={step.image}
              title={step.title}
              content={step.content}
            />
          ))}
        </Container>
      </Box>
      <Box className={styles.support}>
        <Container maxWidth="md">
          <p>
            ご不明点やご不安な点がございましたら、お気軽にお問合せください。
            <br />
            <a
              rel="noreferrer"
              target="_blank"
              href="https://merchant-support.paidy.com/hc/ja"
            >
              加盟店
            </a>
            ヘルプセンターを設けておりますのでご活用ください。
            <br />
            <br />
            …………………………………
            <br />
            <br />
            【お問合せ先】
            <br />
            <br />
            株式会社Paidy
            <br />
            担当：営業本部
            <br />
            <br />
            Email（一般お問い合せ）：
            <a href="mailto:sales@paidy.com">sales@paidy.com</a>
            <br />
            <br />
            Email（技術お問い合せ）：
            <a href="mailto:tech-support@paidy.com">tech-support@paidy.com</a>
          </p>
        </Container>
      </Box>
    </Layout>
  );
};

export default GettingStartedPage;
