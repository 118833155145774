import React from 'react';
import { Hidden } from '@material-ui/core';
import ImgConvenient1 from 'src/images/payments/more-convenient-with-app/convenient-1.svg';
import ImgConvenient2 from 'src/images/payments/more-convenient-with-app/convenient-2.svg';
import ImgConvenient3 from 'src/images/payments/more-convenient-with-app/convenient-3.svg';
import LinearCTAButton from 'src/components/LinearCTAButton';
import styles from './PaymentMoreConvenientWithTheApp.module.scss';

const TITLE = 'アプリでもっと便利に';

const DATA = [
  {
    key: 'payment-more-convenient-with-app-1',
    img: ImgConvenient1,
    text: (
      <>
        利用履歴をいつでもすぐに確認。
        <Hidden smUp>
          <br />
        </Hidden>
        使い過ぎを防止。
      </>
    ),
  },
  {
    key: 'payment-more-convenient-with-app-2',
    img: ImgConvenient2,
    text: (
      <>
        毎月のご請求のお支払いも
        <Hidden smUp>
          <br />
        </Hidden>
        コンビニで見せるだけ。
      </>
    ),
  },
  {
    key: 'payment-more-convenient-with-app-3',
    img: ImgConvenient3,
    text: (
      <>
        欲しい商品を見つけて
        <Hidden smUp>
          <br />{' '}
        </Hidden>
        スムーズに購入。
      </>
    ),
  },
];

export default function PaymentMoreConvenientWithTheApp({
  title = TITLE,
  data = DATA,
}) {
  return (
    <section className={styles.section}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.container}>
        {data.map((item, index) => (
          <div className={styles.item} key={item.key}>
            <img
              src={item.img}
              alt={`payment-more-convenient-with-app-${index}`}
              className={styles.img}
            />
            <p className={styles.text}>{item.text}</p>
          </div>
        ))}
      </div>
      <LinearCTAButton
        text="アプリの詳細を見る"
        to="/guide#paidy-app"
        className="ga_click_payments_button"
      />
    </section>
  );
}
