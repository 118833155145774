import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import styles from './PaidyCard.module.scss';

const PaidyCard = () => {
  return (
    <section className={styles.section} id="paidy-card">
      <h2 className={styles.sectionTitle}>ペイディカードを使ってみよう</h2>
      <div className={styles.sectionTitleDescription}>
        <p>
          ペイディカード（バーチャルカード）を最短5分で即日・無料発行。Visaマークのあるネットショップなら、どこでもペイディでお買い物を楽しめます。さらに、リアルカードを発行すると、街のお店でもペイディが使えます。
        </p>
      </div>

      <Link
        to="/paidycard/"
        className={cx(
          styles.aboutPaidycardButton,
          'ga_click_guide_to_details_paidycard'
        )}
      >
        もっと詳しく
      </Link>
    </section>
  );
};

export default PaidyCard;
