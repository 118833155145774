import React from 'react';
import cx from 'classnames';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import styles from './ThreePay.module.scss';
import HorizontalScrollNavigation from '../HorizontalScrollNavigation';

const ThreePayProcessItems = [
  {
    ImgComponent: () => {
      return (
        <div className={styles.appIconContainer}>
          <div className={styles.appIcon}>
            <img
              src={require('../../../images/qr-code-guide.svg')}
              alt={'QRcode'}
            />
          </div>
        </div>
      );
    },
    figCaption: 'ペイディアプリから本人確認をする。',
  },
  {
    ImgComponent: () => (
      <img
        src={require('../../../images/guide-3pay-1.svg')}
        alt={'ショッピングカートのアイコン'}
      />
    ),
    figCaption: 'ショップで欲しい商品をカートに入れる。',
  },
  {
    ImgComponent: () => (
      <img
        src={require('../../../images/guide-3pay-2.svg')}
        alt={'ペイディアプリのメールアドレス、携帯電話番号の入力フォームの画像'}
      />
    ),
    figCaption:
      'お支払い方法で「あと払い（ペイディ）」を選択して、メアドと携帯番号を入力する。',
  },
  {
    ImgComponent: () => (
      <img
        src={require('../../../images/guide-3pay-3.svg')}
        alt={'３回あと払いのボタンの画像'}
      />
    ),
    figCaption: (
      <>
        ご希望のお支払い回数を選んで完了。
        <br />
        <br />
        <p className={styles.small}>
          ※選択できる支払い回数は加盟店により異なります。
        </p>
      </>
    ),
  },
];

const ThreePay = () => {
  const data = useStaticQuery(graphql`
    query {
      tabUsage: file(relativePath: { eq: "guide-tab-usage.png" }) {
        childImageSharp {
          fixed(width: 184) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      threePaySelect: file(relativePath: { eq: "guide-3pay-select.png" }) {
        childImageSharp {
          fixed(width: 184) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      threePay: file(relativePath: { eq: "guide-3pay.png" }) {
        childImageSharp {
          fixed(width: 184) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const ThreePayAfterBuyingProcessItems = [
    {
      ImgComponent: () => (
        <Img
          fixed={data.tabUsage.childImageSharp.fixed}
          alt={'ペイディアプリのタブ画像'}
        />
      ),
      figCaption: '「ご利用履歴」をタップ。',
    },
    {
      ImgComponent: () => (
        <img
          width={184}
          height={184}
          src={require('src/images/3pay-buying-2.png')}
          alt={'ご利用履歴画面より、３回あと払いを選択できる画面'}
        />
      ),
      figCaption: '「分割払いに変更」をタップ',
    },
    {
      ImgComponent: () => (
        <img
          width={184}
          height={184}
          src={require('src/images/3pay-buying-3.png')}
          alt={'３回あと払いに変更した際の画面'}
        />
      ),
      figCaption: (
        <>
          ご希望のお支払い回数を選んで完了。
          <br />
          <br />
          <p className={styles.small}>
            ※ 選択できる支払い回数は加盟店により異なります。
          </p>
        </>
      ),
    },
  ];

  return (
    <section className={styles.section} id="3pay">
      <h2 className={styles.sectionTitle}>3・6・12回あと払いを使ってみよう</h2>
      <div className={styles.sectionTitleDescription}>
        <p>
          分割手数料無料*で、お支払いを3・6・12回に分けることができます。お支払い総額はそのままで月々のお支払いを調整できるので、よりかしこく計画的にお買い物を楽しめます。
          <br />
          <br />
          <Link
            to="/npay/"
            className={cx(
              styles.aboutPaidycardButton,
              'ga_click_guide_to_details_npay'
            )}
          >
            もっと詳しく
          </Link>
          <p className={styles.note}>
            *口座振替・銀行振込のみ無料
            <br />
            <br />
            ※選択できる支払い回数は加盟店により異なります。
            <br />
            <br />
            ※３回あと払いは1回のお買い物につき、Amazonは3円以上、その他のショップでは3,000円以上のお支払いで利用可能です。6回あと払いは1回のお買い物につき、Amazonは6円以上、その他のショップでは6,000円以上のお支払いで利用可能です。12回あと払いは1回のお買い物につき、12,000円以上でご利用いただけます。一部のショップでは3・6・12回あと払いはご利用いただけません。
            <br />
            <br />
            ※ペイディカードで３回あと払いを利用する場合は、店舗での購入時に分割払いを選択せず、一括払いで購入後、ペイディアプリから３回あと払いに変更してください。
          </p>
        </p>
      </div>
      <HorizontalScrollNavigation items={ThreePayProcessItems} />
      <section className={styles.subsection} id="3pay-after-buying">
        <h3 className={styles.subsectionTitle} style={{ marginBottom: 24 }}>
          購入後にアプリで変更する場合
        </h3>
        <HorizontalScrollNavigation items={ThreePayAfterBuyingProcessItems} />
      </section>
    </section>
  );
};

export default ThreePay;
