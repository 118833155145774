// @flow
import React, { useState } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import LinkBox from './LinkBox';
import styles from './HeaderMobile.module.scss';
import { mobileMainMenu } from './const';

const HeaderMobile = ({
  pathname,
  isCompanyPage,
  hasNoHeaderNav,
  hasNoHeader,
  alwaysWhite,
}: {
  pathname: string,
  isCompanyPage?: boolean,
  hasNoHeaderNav?: boolean,
  hasNoHeader?: boolean,
  alwaysWhite?: boolean,
}) => {
  const [isHeaderNavActive, setIsHeaderNavActive] = useState(false);
  const [hasWhiteBg, setHasWhiteBg] = React.useState(false);

  const onClick = () => {
    setIsHeaderNavActive(false);
  };

  React.useEffect(() => {
    const updateBgOnScroll = () => {
      setHasWhiteBg(window.scrollY > 0);
    };

    window.addEventListener('scroll', updateBgOnScroll);

    return () => window.removeEventListener('scroll', updateBgOnScroll);
  }, []);

  return (
    !hasNoHeader && (
      <div
        className={cx(
          styles.headerWrapper,
          { [styles.isCompanyPage]: isCompanyPage },
          {
            [styles.hasNoHeaderNav]: hasNoHeaderNav,
          },
          (hasWhiteBg || isCompanyPage || alwaysWhite) && styles.whiteBg
        )}
      >
        <header
          className={cx(styles.header, {
            [styles.isActive]: isHeaderNavActive,
          })}
        >
          <div className={styles.topBlock}>
            {!hasNoHeaderNav && (
              <button
                className={cx(styles.menu, 'a11y')}
                onClick={() => setIsHeaderNavActive(!isHeaderNavActive)}
              >
                Menu
              </button>
            )}
            <Link
              to="/"
              className={cx(styles.logo, 'ga_click_paidy_logo')}
              onClick={onClick}
            >
              <img
                alt="paidy"
                src={require('src/images/logo-color-10th.svg')}
                width={128}
                height={32}
                style={{
                  display: 'block',
                  willChange: 'height',
                  transition: 'height 0.2s ease',
                }}
              />
            </Link>
            {!hasNoHeaderNav && (
              <OutboundLink
                className={styles.btnLogin}
                href="https://my.paidy.com/login/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={onClick}
              >
                ログイン
              </OutboundLink>
            )}
          </div>
          {isHeaderNavActive && (
            <nav className={styles.mainNav}>
              {mobileMainMenu.map((menuItem) =>
                menuItem.subMenu ? (
                  <LinkBox
                    className={cx(
                      pathname.startsWith(menuItem.link) ? styles.active : ''
                    )}
                    to={menuItem.link}
                    text={menuItem.title}
                    key={menuItem.title}
                    activeSubMenu={
                      pathname ? pathname.startsWith(menuItem.link) : false
                    }
                  >
                    <nav>
                      {menuItem.subMenu.map((subMenuItem) => (
                        <Link
                          to={subMenuItem.link}
                          onClick={onClick}
                          className={cx({
                            [styles.active]:
                              pathname.endsWith(subMenuItem.link) ||
                              (!subMenuItem.rootLink &&
                                pathname.includes(subMenuItem.link)),
                          })}
                          key={subMenuItem.title}
                        >
                          {subMenuItem.title}
                        </Link>
                      ))}
                    </nav>
                  </LinkBox>
                ) : (
                  <Link
                    className={cx(
                      'ga_click_global_navigation',
                      pathname.startsWith(menuItem.link) ? styles.active : ''
                    )}
                    to={menuItem.link}
                    onClick={onClick}
                    key={menuItem.title}
                  >
                    {menuItem.title}
                  </Link>
                )
              )}
              <Link
                className={styles.toMerchant}
                to="/merchant/"
                onClick={onClick}
              >
                事業者の方へ
              </Link>
            </nav>
          )}
        </header>
      </div>
    )
  );
};

HeaderMobile.defaultProps = {
  siteTitle: ``,
  isTransparent: false,
  isCompanyPage: false,
  hasNoHeaderNav: false,
  hasNoHeader: false,
  alwaysWhite: false,
};

export default HeaderMobile;
