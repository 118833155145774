import React from 'react';
import cx from 'classnames';
import { Link } from 'react-scroll';
import styles from './PaidyApp.module.scss';
import HorizontalScrollNavigation from '../HorizontalScrollNavigation';

const items = [
  {
    ImgComponent: () => {
      return (
        <div className={styles.appIconContainer}>
          <div className={styles.appIcon}>
            <img
              src={require('../../../images/qr-code-guide.svg')}
              alt={'QRcode'}
            />
          </div>
        </div>
      );
    },
    figCaption: 'ペイディアプリをダウンロード。',
  },
  {
    ImgComponent: () => (
      <img
        src={require('../../../images/guide-enjoy-shopping-2.svg')}
        alt={'スマートフォンのアイコン'}
      />
    ),
    figCaption: 'メールアドレスと携帯電話番号を入力。',
  },
  {
    ImgComponent: () => (
      <img
        src={require('../../../images/guide-enjoy-shopping-3.svg')}
        alt={'認証コードを入力したスマートフォンの画像'}
      />
    ),
    figCaption: 'SMSで届いた認証コードを入力。',
  },
  {
    ImgComponent: () => (
      <img
        src={require('../../../images/guide-paidyapp-4.svg')}
        alt={'ペイディアプリのログイン完了画面の画像'}
      />
    ),
    figCaption: 'ログイン完了。',
  },
];

const PaidyApp = () => {
  return (
    <section className={styles.section} id="paidy-app">
      <h2 className={styles.sectionTitle}>アプリでもっと便利に使おう</h2>
      <div className={styles.sectionTitleDescription}>
        <ul className={styles.customUl}>
          <li>ご利用履歴を、いつでも、すぐに確認できます。</li>
          <li>毎月のご請求のお支払いもコンビニでアプリを見せるだけ。</li>
          <li>
            「お買い物」機能で、あなたにぴったりの商品をご紹介。気に入った商品をペイディでスムーズに購入できます。
          </li>
          <li>
            本人確認をすると、ペイディカードや3・6・12回あと払いが利用可能に。
          </li>
          <li className={styles.noDecoration}>
            <Link
              to="kyc"
              smooth
              duration={500}
              className={cx(styles.link, 'ga_click_guide_to_details')}
            >
              本人確認の詳細はこちら
            </Link>
          </li>
        </ul>
      </div>
      <HorizontalScrollNavigation items={items} />
    </section>
  );
};

export default PaidyApp;
